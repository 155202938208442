/* eslint-disable max-lines */
import {rgba, darken, lighten} from 'polished';
import {getConfig} from '@/app/utils/general';

import {
  IBackdropMenu,
  IButton,
  ICallus,
  ICarousel,
  ICarouselItem,
  ICarouselNavigation,
  ICheckboxFiled,
  ICheckmark,
  IClose,
  ICookies,
  ICopyright,
  ICpfFiled,
  ICreditCardFiled,
  ICvvField,
  IDataBox,
  IDateField,
  IDropDown,
  IError,
  IExpanded,
  IExpandedContent,
  IFiledError,
  IFooter,
  IHamburger,
  IHeader,
  IHelper,
  IIcon,
  IIconInfo,
  IIndicator,
  IInput,
  IInputFiled,
  IInputNumberField,
  IItemBox,
  ILabel,
  ILanguagePicker,
  ILink,
  IListItem,
  IMainCarouselItem,
  IModal,
  IContentItem,
  IMoviePlayer,
  INotifications,
  IParagraph,
  IPriceDistribution,
  IRating,
  IScrollTop,
  ISearcher,
  ISelect,
  ISelectFiled,
  ISeparator,
  IStepper,
  ITag,
  ITestimonial,
  ITextareaField,
  ITitle,
  ITooltip,
  IVideoCoverCarousel,
  ITagWrapper,
  IPlayerIconsInfo,
  IPlayList,
  ITestimonialSingle,
} from '../../theme.interface';

const config = getConfig();
const {colors, fontFamily, others, GRID_BREAKPOINTS, CONTAINER_MAX_WIDTHS} = config.STYLEGUIDE;

const TITLE: ITitle = {
  h1: {
    fontSize: '36px',
    lineHeight: '40px',
    fontWeight: '600',
    fontFamily: fontFamily.title,
    color: others.textColor,
    md: {
      fontSize: '40px',
      lineHeight: '48px',
    },
  },
  h2: {
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: '600',
    fontFamily: fontFamily.title,
    color: others.textColor,
    md: {
      fontSize: '36px',
      lineHeight: '40px',
    },
  },
  h3: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '600',
    fontFamily: fontFamily.title,
    color: others.textColor,
  },
  h4: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '700',
    fontFamily: fontFamily.title,
    color: others.textColor,
  },
  GRID_BREAKPOINTS,
};

const PARAGRAPH: IParagraph = {
  color: others.textColor2,
  types: {
    p1: {
      fontSize: '16px',
      lineHeight: '24px',
      color: others.textColor2,
      md: {
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '26px',
      },
    },
    p2: {
      fontSize: '14px',
      lineHeight: '24px',
      color: others.textColor2,
    },
    p3: {
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: '400',
      color: others.textColor2,
    },
    p4: {
      fontSize: '12px',
      lineHeight: '16px',
      color: others.textColor2,
    },
    p5: {
      fontSize: '10px',
      lineHeight: '14px',
      textAlign: 'center',
      color: others.textColor2,
    },
  },
  GRID_BREAKPOINTS,
};

const ICON: IIcon = {
  fill: colors.white,
};

const CLOSE: IClose = {
  height: 12,
  width: 1,
  color: colors.medium,
};

const LABEL: ILabel = {
  color: others.textColor,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '700',
};

const FIELDERROR: IFiledError = {
  error: colors.error,
};

const BUTTON: IButton = {
  fontColor: colors.white,
  background: colors.brand,
  info: colors.info,
  infoHover: colors.infoHover,
  brand: colors.brand,
  brandHover: colors.infoHover,
  success: colors.success,
  successHover: colors.successHover,
  danger: colors.error,
  dangerHover: darken(0.2, colors.error),
  warning: colors.warning,
  warningHover: '',
  disabled: colors.light,
  border: 'none',
  fontSize: '14px',
  fontWeight: '700',
  ICON,
};

const INPUT: IInput = {
  color: others.textColor,
  colorActive: others.textColor,
  colorError: colors.error,
  border: colors.inputDisabled,
  borderActive: colors.brand,
  borderError: colors.error,
  background: colors.input,
  backgroundError: lighten(0.3, colors.error),
  backgroundActive: colors.input,
  placeholderColor: others.textColor2,
  placeholderErrorColor: colors.error,
  fontSize: '12px',
};

const TEXTAREAFIELD: ITextareaField = {
  color: others.textColor,
  colorActive: others.textColor,
  error: colors.error,
  background: colors.input,
  backgroundError: lighten(0.3, colors.error),
  backgroundActive: colors.input,
  border: colors.inputDisabled,
  borderActive: colors.brand,
  borderError: colors.error,
  borderRadius: '4px',
  placeholderColor: others.textColor2,
  placeholderErrorColor: colors.error,
  fontSize: '12px',
  FIELDERROR,
  LABEL,
};

const CHECKMARK: ICheckmark = {
  border: colors.inputDisabled,
  borderActive: colors.brand,
  borderChecked: colors.buttonSecond,
  background: colors.input,
  backgroundChecked: colors.success,
  backgroundActive: colors.brand,
  borderRadius: '4px',
};

const SELECT: ISelect = {
  color: others.textColor,
  error: colors.error,
  border: colors.inputDisabled,
  borderError: colors.error,
  background: colors.input,
  backgroundHover: rgba(colors.overlay, .4),
  backgroundError: lighten(0.3, colors.error),
  arrow: colors.medium,
  fontSize: '12px',
  fontSizeOption: '12px',
};

const LINK: ILink = {
  color: others.textColor,
  activeColor: others.textColor,
  background: colors.brand,
  buttonColor: colors.white,
  hoverColor: others.textColor,
  hoverBackground: colors.infoHover,
  hoverTextShadow: others.textColor,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '400',
  buttonFontWeight: '700',
  fontSizeBtn: '14px',
};

const CHECKBOXFIELD: ICheckboxFiled = {
  color: colors.medium,
  error: colors.error,
  CHECKMARK,
  FIELDERROR,
  LABEL,
  INPUT,
  PARAGRAPH,
};

const INPUTFIELD: IInputFiled = {
  color: colors.dark,
  error: colors.error,
  INPUT,
  FIELDERROR,
  LABEL,
};

const DATEFIELD: IDateField = {
  color: colors.dark,
  error: colors.error,
  LABEL,
  SELECT,
  FIELDERROR,
};

const SELECTFIELD: ISelectFiled = {
  SELECT,
  LABEL,
  FIELDERROR,
};

const CREDITCARDFIELD: ICreditCardFiled = {
  iconColor: colors.medium,
  INPUT,
  LABEL,
  FIELDERROR,
};

const SCROLLTOP: IScrollTop = {
  background: colors.brand,
  borderRadius: '50%',
  ICON
};

const STEPPER: IStepper = {
  color: others.textColor2,
  textFontSize: '14px',
  CHECKMARK: {
    ...CHECKMARK,
    backgroundChecked: colors.success
  },
};

const CALLUS: ICallus = {
  color: others.textColor2,
  fontWeight: '400',
  fontSize: '13px',
  borderColor: colors.light,
  brand: others.textColor2,
  LINK: {
    ...LINK,
    color: others.textColor2,
    fontSize: '13px',
  },
  PARAGRAPH,
};

const COPYRIGHT: ICopyright = {
  PARAGRAPH,
};

const DROPDOWN: IDropDown = {
  buttonBorderColor: colors.dropdownDisabled,
  buttonBackground: colors.dropdown,
  buttonBackgroundOpen: colors.dropdown,
  buttonBorderOpen: colors.brand,
  buttonTextColor: colors.medium,
  buttonTextColorOpen: colors.brand,
  buttonTextFontSize: '12px',
  carretColor: colors.medium,
  listBackground: colors.dropdown,
  listBorder: colors.dropdownDisabled,
  listElementBackgroundHover: rgba(colors.overlay, .4),
  listElementTextHover: colors.medium,
  listElementText: colors.medium,
  listElementFontSize: '12px',
  listCarretColor: colors.success,
  iconColor: colors.medium,
  BUTTON: {
    ...BUTTON,
    background: colors.dropdown,
  },
};

const EXPANDED: IExpanded = {
  borderBottom: colors.light,
  active: colors.medium,
  hover: colors.medium,
  PARAGRAPH,
  LINK,
};

const EXPANDEDCONTENT: IExpandedContent = {
  TITLE,
  EXPANDED,
};

const HAMBURGER: IHamburger = {
  color: colors.medium,
  border: colors.inputDisabled,
  background: colors.buttonSecond,
  hoverLineBackground: colors.brand,
};

const HELPER: IHelper = {
  background: colors.lighter,
  borderColor: colors.lighter,
  borderRadius: '4px',
  dotBackground: others.textColor2,
  TITLE,
  PARAGRAPH,
};

const ICONINFO: IIconInfo = {
  color: others.textColor2,
  iconFill: colors.medium,
  fontSize: '13px',
};

const LANGUAGEPICKER: ILanguagePicker = {
  DROPDOWN
};

const MODAL: IModal = {
  wrapperBackground: rgba(colors.background, 0.7),
  background: colors.lighter,
  border: colors.inputDisabled,
  borderRadius: '4px',
};

const CONTENT_ITEM: IContentItem = {
  borderRadius: '4px',
  background: rgba(colors.overlay, .4),
  genresHover: colors.medium,
  durationHover: colors.medium,
  iconFill: colors.medium,
  removeBackground: colors.buttonSecond,
  removeBackgroundHover: colors.brand,
  borderColor: colors.inputDisabled,
  borderColorHover: colors.brand,
  iconColorHover: colors.white,
  titleHoverBackground: colors.background,
  CLOSE: {
    ...CLOSE,
    height: 13,
  },
  TITLE,
  ICONINFO,
  LINK,
  GRID_BREAKPOINTS,
};

const SEARCHER: ISearcher = {
  btnBorder: colors.inputDisabled,
  btnBackground: colors.buttonSecond,
  iconFill: colors.medium,
  ...INPUT,
  borderActive: colors.brand,
  background: colors.buttonSecond,
  border: colors.inputDisabled,
  backgroundActive: colors.buttonSecond,
  color: others.textColor,
  GRID_BREAKPOINTS,
};

const TAG: ITag = {
  color: colors.medium,
  fontSize: '14px',
  fontWeight: '400',
  checkedFontWeight: '700',
  background: colors.buttonSecond,
  checkedColor: colors.white,
  borderColor: colors.lighter,
  active: colors.infoHover,
  checkmarkColor: colors.inputDisabled,
  CHECKMARK: {
    ...CHECKMARK,
    background: colors.white,
  },
};

const RATING: IRating = {
  fill: colors.warning,
  fillBack: colors.medium,
};

const TESTIMONIAL: ITestimonial = {
  titleColor: others.textColor2,
  titleFontWeight: '400',
  fontColor: others.textColor2,
  borderColor: colors.lighter,
  background: colors.lighter,
  GRID_BREAKPOINTS,
  PARAGRAPH,
  RATING,
};

const TESTIMONIAL_SINGLE: ITestimonialSingle = {
  titleColor: colors.medium,
  fontFamily: fontFamily.main,
  fontColor: colors.dark,
  borderColor: '',
  background: '',
  GRID_BREAKPOINTS,
  PARAGRAPH,
  TITLE,
  RATING,
};

const ITEMBOX: IItemBox = {
  TITLE,
  PARAGRAPH,
};

const PRICEDISTRIBUTION: IPriceDistribution = {
  color: others.textColor,
  subtitleColor: others.textColor2,
  subtitleFontSize: '13px',
  activeColor: colors.brand,
  CHECKMARK,
  TITLE,
  PARAGRAPH,
};

const TOOLTIP: ITooltip = {
  background: colors.lighter,
  borderRadius: '4px',
  borderColor: colors.lighter,
  cardFill: colors.brand,
  cardBackgroundColor: colors.white,
  CLOSE,
  TITLE,
  PARAGRAPH,
};

const INDICATOR: IIndicator = {
  activeColor: colors.brand,
  pipColor: colors.light,
};

const CAROUSEL_NAVIGATION: ICarouselNavigation = {
  borderColor: colors.inputDisabled,
  backgroundColor: colors.background,
  iconColor: colors.medium,
  backgroundHoverColor: colors.brand,
  borderHoverColor: colors.brand,
  iconHoverColor: colors.white,
  BUTTON,
};

const CAROUSEL: ICarousel = {
  INDICATOR,
  GRID_BREAKPOINTS,
  TITLE,
  BUTTON,
  CAROUSEL_NAVIGATION,
};

const CAROUSELITEM: ICarouselItem = {};

const BACKDROPMENU: IBackdropMenu = {
  itemActive: colors.brand,
  itemBorder: colors.light,
  background: colors.background,
  LINK: {
    ...LINK,
    color: colors.medium,
    fontSize: '24px',
    lineHeight: '30px',
    fontFamily: fontFamily.title,
  },
};

const COOKIES: ICookies = {
  color: colors.medium,
  background: colors.lighter,
  borderRadius: '4px',
  borderColor: colors.light,
  LINK: {
    ...LINK,
    hoverColor: colors.brand,
  },
  BUTTON: {
    ...BUTTON,
    brand: colors.brand,
    brandHover: colors.infoHover,
    fontColor: colors.white,
    background: colors.background,
  },
  CLOSE,
};

const CPFFIELD: ICpfFiled = {
  ...CREDITCARDFIELD,
};

const CVVFIELD: ICvvField = {
  iconFill: colors.medium,
  iconFillHover: colors.medium,
  ...CREDITCARDFIELD,
};

const MOVIEPLAYER: IMoviePlayer = {
  GRID_BREAKPOINTS,
  PARAGRAPH,
};

const ERROR: IError = {
  color: colors.white,
  TITLE,
  PARAGRAPH,
  BUTTON,
};

const NOTIFICATIONS: INotifications = {
  dangerBackground: colors.error,
  infoBackground: colors.info,
  warningBackground: colors.warning,
  successBackground: colors.success,
  PARAGRAPH: {
    ...PARAGRAPH,
    types: {
      ...PARAGRAPH.types,
      p1: {
        ...PARAGRAPH.types.p1,
        color: colors.white,
      },
    },
  },
};

const DATABOX: IDataBox = {
  borderRadius: '4px',
  background: colors.lighter,
  border: colors.lighter,
  color: others.textColor2,
  buttonFontSize: '12px',
  LABEL,
  INPUT,
  BUTTON: {
    ...BUTTON,
    fontColor: colors.medium,
    info: colors.white,
    infoHover: colors.brand,
    fontWeight: '400',
    hoverColor: colors.white,
  },
};

const MAIN_CAROUSEL_ITEM: IMainCarouselItem = {
  GRID_BREAKPOINTS,
  CONTAINER_MAX_WIDTHS,
  TITLE: {
    ...TITLE,
    h3: {
      ...TITLE.h3,
      color: colors.white,
    },
  },
  PARAGRAPH: {
    ...PARAGRAPH,
    types: {
      ...PARAGRAPH.types,
      p3: {
        ...PARAGRAPH.types.p3,
        color: colors.white,
      },
    },
  },
  ICONINFO: {
    ...ICONINFO,
    color: colors.white,
    fontSize: '14px',
  },
  LINK: {
    ...LINK,
    color: colors.white,
    activeColor: colors.white,
    background: colors.brand,
    buttonColor: colors.white,
    hoverBackground: colors.infoHover,
    hoverTextShadow: colors.brand,
  },
  paragraphFontFamily: fontFamily.main,
  iconTextStyled: colors.medium,
  viewDetailsColor: colors.white,
  viewDetailsFontWeight: '400',
  clockIconColor: colors.white,
  documentIconColor: colors.white,
  starOutlineIconColor: colors.warning,
  preventBackgroundColor: colors.background,
  gradientFirstColor: colors.medium,
  gradientSecondColor: colors.medium,
  gradientFirstColorPercent: 'calc(50% - 800px)',
  gradientSecondColorPercent: 'calc(50% + 800px)',
};

const PLAYER_ICONS_INFO: IPlayerIconsInfo = {
  genresColor: colors.medium,
  durationColor: colors.medium,
  ICONINFO,
}

const PLAYLIST: IPlayList = {
  backgroundColor: colors.white,
  borderColor: colors.light,
  borderRadius: '4px',
  bottomGradientColor: colors.white,
  listElementBorderColor: colors.light,
  playIconFill: colors.brand,
  scrollbarTrack: colors.lighter,
  scrollbarThumb: colors.brand,
  scrollbarThumbHover: colors.infoHover,
  TITLE,
  PARAGRAPH,
  GRID_BREAKPOINTS,
}

const HEADER: IHeader = {
  stickyHeaderBackground: colors.dropdown,
  stickyHeaderBorder: colors.light,
  GRID_BREAKPOINTS,
  CONTAINER_MAX_WIDTHS,
};

const FOOTER: IFooter = {
  border: colors.light,
  background: colors.lighter,
  GRID_BREAKPOINTS,
};

const SWIPER_CAROUSEL = {
  INDICATOR,
};

const VIDEO_COVER_CAROUSEL: IVideoCoverCarousel = {
  background: colors.dark,
  borderColor: colors.medium,
  backgroundIcon: colors.brand,
  colorIcon: colors.white,
  GRID_BREAKPOINTS,
  SWIPER_CAROUSEL,
};

const SEPARATOR: ISeparator = {
  color: colors.light,
};

const INPUTNUMBERFIELD: IInputNumberField = {
  INPUT,
  LABEL,
  FIELDERROR,
};

const LISTITEM: IListItem = {};

const TAGWRAPPER: ITagWrapper = {
  background: colors.white,
  borderColor: colors.light,
};

const sectionTheme = {
  GRID_BREAKPOINTS,
  skeleton: {
    primaryColor: colors.light,
    secondaryColor: colors.medium,
  },
};

const HEADER_NO4 = { ...sectionTheme, TITLE, PARAGRAPH, LINK };

const FEATURES_NO1 = { ...sectionTheme, LINK };

const PREMIUM_NO1 = {
  ...sectionTheme,
  LINK,
  checkIconColor: colors.success,
  arrowColor: colors.brand,
};

const TESTIMONIALS_NO1 = { ...sectionTheme,  };

const PREFOOTER_NO2 = { ...sectionTheme, TITLE, PARAGRAPH, LINK,  };

export const theme = {
  TITLE,
  CALLUS,
  COPYRIGHT,
  EXPANDEDCONTENT,
  HAMBURGER,
  HELPER,
  ICONINFO,
  LINK,
  LANGUAGEPICKER,
  MODAL,
  CONTENT_ITEM,
  PARAGRAPH,
  SEARCHER,
  TAG,
  RATING,
  TESTIMONIAL,
  TESTIMONIAL_SINGLE,
  DROPDOWN,
  INPUT,
  INPUTFIELD,
  CHECKMARK,
  CHECKBOXFIELD,
  TEXTAREAFIELD,
  DATEFIELD,
  SELECTFIELD,
  CREDITCARDFIELD,
  SELECT,
  LABEL,
  FIELDERROR,
  SCROLLTOP,
  STEPPER,
  ICON,
  BUTTON,
  ITEMBOX,
  PRICEDISTRIBUTION,
  CLOSE,
  TOOLTIP,
  INDICATOR,
  CAROUSELITEM,
  CAROUSEL,
  CAROUSEL_NAVIGATION,
  BACKDROPMENU,
  CPFFIELD,
  COOKIES,
  MOVIEPLAYER,
  ERROR,
  NOTIFICATIONS,
  DATABOX,
  MAIN_CAROUSEL_ITEM,
  PLAYER_ICONS_INFO,
  PLAYLIST,
  HEADER,
  CVVFIELD,
  FOOTER,
  VIDEO_COVER_CAROUSEL,
  SWIPER_CAROUSEL,
  SEPARATOR,
  INPUTNUMBERFIELD,
  LISTITEM,
  TAGWRAPPER,
  HEADER_NO4,
  FEATURES_NO1,
  PREMIUM_NO1,
  TESTIMONIALS_NO1,
  PREFOOTER_NO2,
};

export default theme;
